@import "~antd/dist/antd.css";

.App > .ant-row {
  height: 100vh;
}

.right-side {
  text-align: left;
  padding: 30px;
}

.right-side > div {
  margin-bottom: 100px;
}

.offset-window .steps-content {
  min-height: 200px;
  margin-top: 24px;
  padding: 24px;
  text-align: center;
  background-color: #fafafa;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
}

.steps-action {
  margin-top: 24px;
}

.offset-window {
  text-align: center;
  padding-top: 5px;
  top: 50%;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.estimate-emissions-step {
  text-align: center;
}

/*::placeholder{
  color: rgba(0,0,0, .5)
}*/

.legal {
  margin: 100px auto;
  max-width: 880px;
  padding-bottom: 100px;
  /*overflow-y: auto;
  max-height: 92vh;
  width: 92vw;
  margin: 4vh 4vw;*/
}

.estimate-emissions-step form {
  margin-top: 50px;
}

.footer {
  position: static;
  bottom: 0;
  left: 0;
}

.back {
  color: rgb(0, 0, 0);
  position: static;
  top: 0;
  left: 0;
}

.ant-result {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.ant-steps {
  padding: 0 24px;
}

.ant-card-small > .ant-card-body {
  padding: 24px 12px;
}

.footer-item {
  color: rgb(0, 0, 0);
}

.footer-item:hover,
.footer-item:focus {
  color: rgba(0, 0, 0, 0.85);
}

.footer-item:hover,
.footer-item:focus {
  color: rgba(0, 0, 0, 0.85);
}

.copyright {
  cursor: text;
}

.copyright:hover,
.copyright:focus {
  color: rgba(0, 0, 0);
}

#estimate_form_public_key {
  text-align: center;
}

estimate-emissions-step input {
  max-width: 400px;
}

.frosted-glass {
  background: inherit;
}

.frosted-glass:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.5);
  filter: blur(10px);

  background: inherit;
}

@media only screen and (min-width: 992px) {
  .right-side {
    padding: 80px;
  }

  .left-side {
    background-image: url("./img/akudui-OhMfc8ADMd0-unsplash.jpg");
    object-fit: "cover";
  }

  .offset-window {
    padding: 20px;
    margin: 0 50px;
  }

  .footer {
    position: fixed;
  }

  .footer-item {
    color: white;
  }

  .footer-item:hover,
  .footer-item:focus {
    color: rgba(255, 255, 255, 0.85);
  }

  .copyright:hover,
  .copyright:focus {
    color: rgb(255, 255, 255);
  }
}

@media only screen and (min-width: 1200px) {
  .offset-window {
    margin: 0 100px;
  }
}
